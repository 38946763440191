<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		ref="sojourTypeTablePage"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'OstypeId', ascending: 0 }"
		modelId="OstypeId"
		headerTitle="sojourntypes3254"
		tooltipMsg="manulfortypesof"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="SojournTypeTable"
	>
		<template #OstypeOrderSk="list">{{ list.row.SojournTypeCountrySk.OstypeOrder }}</template>

		<template #OstypeIsVisibleHomepageSk="list">
			<boolean-display :boolValue="Boolean(list.row.SojournTypeCountrySk.OstypeIsVisibleHomepage)"></boolean-display>
		</template>

		<template #OstypeOrderCz="list">{{ list.row.SojournTypeCountryCz.OstypeOrder }}</template>

		<template #OstypeIsVisibleHomepageCz="list">
			<boolean-display :boolValue="Boolean(list.row.SojournTypeCountryCz.OstypeIsVisibleHomepage)"></boolean-display>
		</template>

		<template #ShowOnCatalogFilter="list">
			<div v-html="formatCatalogFilter(list.row.SojournTypeCountries)" />
		</template>

		<template #Active="list">
			<div v-html="formatActive(list.row.SojournTypeCountries)" />
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import serviceCommon from '../../services/service/common.service';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import BooleanDisplay from '@/components/common/boolean-display';

import { tableColumns } from './sojourn-type.table-data';

export default {
	name: 'sojourTypeTable',
	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'ObjectSojournTypeListItem',
			columns: tableColumns,
		};
	},

	methods: {
		formatCatalogFilter(typeForcountries) {
			let result = '';
			let yes = this.$i18n.t('yes');
			let no = this.$i18n.t('no');
			typeForcountries.forEach((element) => {
				let char = element.OstypeIsVisibleCatalogSubfilter ? yes : no;
				result += '<div>' + element.CountryCode.toUpperCase() + ': ' + char + '</div>';
			});

			return result;
		},

		formatActive(typeForcountries) {
			let result = '';
			let yes = this.$i18n.t('yes');
			let no = this.$i18n.t('no');
			typeForcountries.forEach((element) => {
				let char = element.OstypeIsEnabled ? yes : no;
				result += '<div>' + element.CountryCode.toUpperCase() + ': ' + char + '</div>';
			});

			return result;
		},

		refresh() {
			this.tableKey += 1;
		},

		redirectToDetail(itemId = 0) {
			this.$router.push({
				name: links.sojournTypeDetailName,
				params: { OstypeId: itemId },
			});
		},

		showDeleteButton() {
			return auth.IsAllowedForUser(enums.role.Admin);
		},

		openModal(itemId) {
			serviceCommon.openDeleteModal(itemId, this.$modal, this.deleteThisItem);
		},

		deleteThisItem(id) {
			serviceCommon.deleteItem(id, this.$refs.sojourTypeTablePage.refresh, this.controllerName);
		},
	},
};
</script>
<style lang="scss">
.SojournTypeTable {
	.VueTables__filters-row > th:nth-child(9) {
		min-width: 125px;
		width: 125px;
	}
}
</style>
