var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: "sojourTypeTablePage",
        staticClass: "SojournTypeTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "OstypeId", ascending: 0 },
          modelId: "OstypeId",
          headerTitle: "sojourntypes3254",
          tooltipMsg: "manulfortypesof",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "OstypeOrderSk",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(list.row.SojournTypeCountrySk.OstypeOrder)),
                ]
              },
            },
            {
              key: "OstypeIsVisibleHomepageSk",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: {
                      boolValue: Boolean(
                        list.row.SojournTypeCountrySk.OstypeIsVisibleHomepage
                      ),
                    },
                  }),
                ]
              },
            },
            {
              key: "OstypeOrderCz",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(list.row.SojournTypeCountryCz.OstypeOrder)),
                ]
              },
            },
            {
              key: "OstypeIsVisibleHomepageCz",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: {
                      boolValue: Boolean(
                        list.row.SojournTypeCountryCz.OstypeIsVisibleHomepage
                      ),
                    },
                  }),
                ]
              },
            },
            {
              key: "ShowOnCatalogFilter",
              fn: function (list) {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formatCatalogFilter(list.row.SojournTypeCountries)
                      ),
                    },
                  }),
                ]
              },
            },
            {
              key: "Active",
              fn: function (list) {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formatActive(list.row.SojournTypeCountries)
                      ),
                    },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          1355340011
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }