export const tableColumns = [
	{
		model: 'OstypeId',
		i18n: 'id2541',
		hidden: true,
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'OstypeNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OstypeOrderSk',
		i18n: 'ordersk',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'OstypeIsVisibleHomepageSk',
		i18n: 'onhomepagesk',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'OstypeNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OstypeOrderCz',
		i18n: 'ordercz',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'OstypeIsVisibleHomepageCz',
		i18n: 'onhomepagecz',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'OstypeNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OstypeNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OstypeNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OstypeNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'ShowOnCatalogFilter',
		i18n: 'oncatalog',
		sortable: false,
	},
	{
		model: 'Active',
		i18n: 'active',
		sortable: false,
	},
];
